import { parseCookies } from "nookies";
import React, { useEffect } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import Style from "./FormPayment.module.scss";
import useFormPayment from "./useFormPayment";
import Modal from "react-bootstrap/Modal";
import useApplePay from "./useApplePay";
import platform from "platform";

import { useLocation } from "react-router-dom";

const Formpayment = ({ userData, basicData }) => {
  let paymentOptionStatus = userData?.data?.payment_option;
  const location = useLocation();
  const currentPath = location.pathname;
  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";
  const {
    show,
    iFrame,
    cardClick,
    paymentError,
    pendingModal,
    applePayClick,
    paymentStatus,
    loadingShimmer,
    t,
    pay,
    cardSelect,
    handleHide,
    getDropList,
    applePaySelect,
    paymentConfigure,
    setLoadingShimmer,
  } = useFormPayment();

  const { handleOnApplePayButtonClick, appleErrorMessage } = useApplePay();

  useEffect(() => {
    // if(paymentStatus !==0 ){

    // }
    const scrptEl = document.createElement("script");
    scrptEl.src = process.env.REACT_APP_MASTERCARD_GATEWAY;

    scrptEl.async = true;
    document.body.appendChild(scrptEl);

    return () => {
      document.body.removeChild(scrptEl);
    };
  }, []);

  useEffect(() => {
    if ((paymentOptionStatus == "1" || paymentOptionStatus == "2") && parseCookies()?.SESSION_ID) {
      paymentConfigure(parseCookies()?.SESSION_ID);
    }
  }, [parseCookies()?.SESSION_ID, paymentOptionStatus]);

  useEffect(() => {
    if (applePayClick) {
      try {
        let button = document.getElementById("pay-button");
        button.addEventListener("click", () => {
          handleOnApplePayButtonClick();
        });
      } catch (error) {
        console.log("Apple Pay Button onClick event Error: ", error);
      }
    }
  }, [applePayClick]);

  return (
    <div
      className={`${Style.payment}  ${rtl}`}
      style={{ display: paymentOptionStatus == "1" || paymentOptionStatus == "2" ? "" : "none" }}
    >
      {typeof paymentStatus !== "object" ? (
        <Modal show={show} centered className={`${Style.checkout_modal}`}></Modal>
      ) : (
        <Modal
          show={show}
          centered
          className={`${Style.checkout_iframe_modal} ${pendingModal && Style.bg_transparent}`}
        >
          {pendingModal && (
            <>
              <Modal.Header
                onClick={handleHide}
                className={Style.checkout_modal_header}
              ></Modal.Header>
              <Modal.Body className={`text-center ${Style.checkout_modal_body}`}>
                <div className={`${Style.message_title} ${Style.spinner_wrap}`}>
                  <div className="html-spinner"></div>
                </div>
              </Modal.Body>
            </>
          )}

          {iFrame && !pendingModal && (
            <div className="modal-body" dangerouslySetInnerHTML={{ __html: iFrame }} />
          )}
        </Modal>
      )}
      {basicData?.data?.card_payment == "1" && (
        <div className={Style.checkbox}>
          <Form.Check
            type="radio"
            name="type"
            id="card"
            defaultChecked={true}
            label={""}
            onClick={() => {
              cardSelect();
            }}
          />
          <div className={Style.check_label}>
            <div>
              <p>{t("cardType")}</p>
              <span>{t("cardPay")}</span>
            </div>
          </div>
        </div>
      )}
      {basicData?.data?.card_payment == "1" && (
        <div className={Style.payment_input} style={{ display: !cardClick && "none" }}>
          <div className={Style.input_wrap}>
            <FloatingLabel controlId="cardNumber" className={Style.form_group}>
              <Form.Control
                id="card-number"
                placeholder={t("Card_Number")}
                readOnly
                className={Style.form_control}
              />
            </FloatingLabel>
            <div className={Style.dropdown_wrap}>
              <FloatingLabel controlId="Month" className={`${Style.form_group} ${Style.dropdown}`}>
                <Form.Select
                  placeholder="MM"
                  className={Style.form_control}
                  id="expiry-month"
                  readOnly
                >
                  <option value="1">Jan</option>
                  <option value="2">Feb</option>
                  <option value="3">Mar</option>
                  <option value="4">Apr</option>
                  <option value="5">May</option>
                  <option value="6">Jun</option>
                  <option value="7">Jul</option>
                  <option value="8">Aug</option>
                  <option value="9">Sep</option>
                  <option value="10">Oct</option>
                  <option value="11">Nov</option>
                  <option value="12">Dec</option>
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId="Year" className={`${Style.form_group} ${Style.dropdown}`}>
                <Form.Select
                  placeholder="YY"
                  className={Style.form_control}
                  id="expiry-year"
                  readOnly
                >
                  {getDropList()}
                </Form.Select>
              </FloatingLabel>
            </div>
          </div>
          <div className={Style.input_wrap}>
            <FloatingLabel controlId="cardHolder" className={Style.form_group}>
              <Form.Control
                type="text"
                id="cardholder-name"
                readOnly
                placeholder={t("Card_Holder_Name")}
                className={Style.form_control}
              />
            </FloatingLabel>
            <FloatingLabel controlId="Security" className={Style.form_group_input}>
              <Form.Control
                type="password"
                className="number"
                id="security-code"
                placeholder={t("Security")}
                readOnly
                maxLength="4"
              />
            </FloatingLabel>
          </div>
        </div>
      )}

      {basicData?.data?.apple_pay == "1" && platform.name == "Safari" && (
        <div className={Style.checkbox}>
          <Form.Check
            type="radio"
            name="type"
            id="set_date"
            label={""}
            onClick={() => {
              applePaySelect();
            }}
          />

          <div className={Style.check_label}>
            <div>
              <p>{t("Applypay")}</p> <span className={Style.applepay}>{t("appleSupport")}</span>
              {applePayClick ? (
                <h4>
                  <apple-pay-button
                    id="pay-button"
                    buttonstyle="black"
                    type="plain"
                    locale="en-US"
                  />
                </h4>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}

      {basicData?.data?.card_payment == "1" && (
        <div className={Style.btn_wrap}>
          {!applePayClick && (
            <button
              type="button"
              // className="custom_btn custom_btn-primary"

              className={`${
                loadingShimmer
                  ? "loading custom_btn custom_btn-primary"
                  : "custom_btn custom_btn-primary"
              }`}
              id="payButton"
              onClick={() => {
                pay("card");
                setLoadingShimmer(true);
              }}
            >
              {t("PAY")}
            </button>
          )}
        </div>
      )}
      <small className={`error`}>{paymentError && <span>{paymentError}</span>}</small>
      {appleErrorMessage && applePayClick && <span className={`error`}>{appleErrorMessage}</span>}
    </div>
  );
};

export default Formpayment;
