import { useEffect } from "react";
import i18n from "../../i18n";
import { useParams, useNavigate } from "react-router-dom";
import { useSwrStatic } from "../../helpers/swr";
import { setCookie } from "nookies";
import { useTranslation } from "react-i18next";

const useSubscription = () => {
  const { lang: langSlug } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (langSlug === "ar") {
      localStorage.setItem("language_type", "ar");
    } else if (langSlug === "en") {
      localStorage.setItem("language_type", "en");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(langSlug);
  }, [langSlug]);

  const { data: userData } = useSwrStatic(`/user-details`);
  const { data: basicData } = useSwrStatic(`/basic-details`);
  const { data: sessionData } = useSwrStatic(
    userData?.data?.payment_option == "1" || userData?.data?.payment_option == "2"
      ? `/create-session`
      : null
  );

  if (sessionData) {
    setCookie(null, "ORDER_ID", sessionData?.data?.id);
    setCookie(null, "SESSION_ID", sessionData?.data?.session_id);
  }

  return { t, sessionID: sessionData?.data?.session_id, userData, basicData };
};

export default useSubscription;
