import Style from "./Summary.module.scss";
import TermsandConditions from "../TermsandConditions";
import useSummary from "./useSummary";
import SubscriptionSuccess from "../SubscriptionSuccess";
import SubscriptionDate from "../SubscriptionDate";
import { useEffect } from "react";
import { destroyCookie } from "nookies";
import TransactionFailed from "./transaction-failed.svg";
import { useNavigate } from "react-router-dom";

const Summary = () => {
  const { t, userData, paymentStatus } = useSummary();
  let status = paymentStatus?.data?.payment_status;
  // const handleRouteChange = () => {
  //   destroyCookie(null, "SESSION_ID");
  //   setTimeout(() => {
  //     destroyCookie(null, "USER_ACCESS_TOKEN");
  //     destroyCookie(null, "ORDER_ID");
  //     destroyCookie(null, "userID");
  //   }, 1000);
  // };

  // useEffect(() => {
  //   return () => {
  //     handleRouteChange();
  //   };
  // }, []);

  const navigate = useNavigate();
  const lang = localStorage.getItem("language_type");
  return (
    <>
      <section className={Style.delivery_subscription}>
        <div className="container">
          <div className="row">
            <div className={`col-lg-6 ${status == "2" ? Style.failedClass : ""}`}>
              <div className={`${Style.desktop}`}>
                {status == "1" && <SubscriptionSuccess t={t} />}
                {status == "2" && (
                  <div className={Style.contentWrapper}>
                    <div class={Style.fig_wrapper}>
                      <figure class={Style.fig_img_wrapper}>
                        <img src={TransactionFailed} alt="Transaction Failed" />
                      </figure>
                    </div>
                    <div className={Style.message_title}>{t("order_failed")}</div>
                    <div className={`${Style.message_status} ${Style.error}`}>
                      {t("Status_error_msg")}
                    </div>
                    <button
                      className={"custom_btn custom_btn-primary"}
                      onClick={() => {
                        navigate(`/subscription/${lang}`);
                        window.location.reload();
                      }}
                    >
                      {t("Retry")}
                    </button>
                    <button
                      className={"custom_btn custom_btn-primary"}
                      onClick={() => {
                        navigate(`/`);
                        window.location.reload();
                      }}
                    >
                      {t("Home")}
                    </button>
                  </div>
                )}
                {status == "1" && (
                  <>
                    <SubscriptionDate t={t} props={userData} />{" "}
                    {/* <button
                      className={"custom_btn custom_btn-primary"}
                      onClick={() => {
                        navigate(`/`);
                        window.location.reload();
                      }}
                    >
                      {t("Home")}
                    </button> */}
                  </>
                )}
              </div>
            </div>
            {status == "1" && (
              <div className="col-lg-6 ">
                <figure>
                  <img src="/assets/images/subscription-poster.png" alt="poster" />
                </figure>
                <div className={Style.mobile}></div>
              </div>
            )}
          </div>
          {status == "1" && <TermsandConditions t={t} />}
        </div>
      </section>
    </>
  );
};

export default Summary;
